@use '@hellodarwin/core/scss/_variables' as variables;

.dashboard-collapse {
  .ant-collapse-item {
    .ant-collapse-header {
      padding: 0;
      padding-right: 30px;
    }
    .ant-collapse-content {
      padding: 16px 30px;
      border-top: 1px solid variables.$hd-grey-5;
      .ant-collapse-content-box {
        padding: 0;
      }
    }
  }
}
