@use '@hellodarwin/core/scss/_variables' as variables;
@use '@hellodarwin/core/scss/antd-common' as *;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,500;0,600;0,700;1,500;1,600;1,700&display=swap');
@import url('https://use.typekit.net/lht6zme.css');

/*------------------------- static ------------------------- */
html {
  scroll-behavior: smooth;
}

body {
  scroll-behavior: smooth;
  overflow-x: hidden;
  font-family: 'Inter', sans-serif;
  margin: 0;
  * {
    box-sizing: border-box;
  }
}

a {
  color: variables.$hd-purple-2;
  &:hover {
    color: variables.$hd-purple-1;
  }
}
